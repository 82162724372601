import { Directive, ElementRef, Input, Renderer2, HostListener, OnInit, DoCheck } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appCharacterCount]'
})
export class CharacterCountDirective implements OnInit, DoCheck {
  @Input('appCharacterCount') maxLength: number = 1000; // Default max length
  private counterElement: HTMLElement;
  private lastValue: string = '';

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    // Create the counter element
    this.counterElement = this.renderer.createElement('div');
    this.renderer.addClass(this.counterElement, 'character-count');
    this.updateCharacterCount();

    // Append the counter element after the input field
    const parent = this.el.nativeElement.parentElement;
    this.renderer.appendChild(parent, this.counterElement);
  }

  // @HostListener('input', ['$event.target.value']) onInput(value: string) {
  //   this.updateCharacterCount();
  // }

  @HostListener('input', ['$event.target']) onInput(target : HTMLInputElement) {
    let value = target.value;

    // Enforce Maxlength
    if(value.length > this.maxLength) {
      value = value.substring(0, this.maxLength);
      target.value = value;
    }

    this.updateCharacterCount();
  }

  ngDoCheck() {
    // Check for changes to the element's value
    const currentValue = this.el.nativeElement.value || '';
    if (currentValue !== this.lastValue) {
      this.lastValue = currentValue;
      this.updateCharacterCount();
    }
  }

  private updateCharacterCount() {
    const currentLength = this.el.nativeElement.value.length || 0;
    const remainingChars = `${currentLength}/${this.maxLength} characters`;
    this.counterElement.innerHTML = remainingChars;
  }
}
