import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { SessionStorageService } from 'angular-web-storage';
import { AuthService } from '../auth.service';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, RouterModule, Routes, Router } from '@angular/router';
import { NetworkStatusService } from 'src/app/network-status.service.ts.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { ReleasenoteComponent } from '../releasenote/releasenote.component';
import { CompanyService } from '../company/company.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
  public sidebarOpened = false;
  public cookieIp = "";
  firstname;
  lastname;
  image = '';
  companyLogo: any;
  companyName: any;
  toggleOffcanvas() {
    this.sidebarOpened = !this.sidebarOpened;
    if (this.sidebarOpened) {
      document.querySelector('.sidebar-offcanvas').classList.add('active');
    }
    else {
      document.querySelector('.sidebar-offcanvas').classList.remove('active');
    }
  }
  constructor(public dialog: MatDialog, private cookieService: CookieService, config: NgbDropdownConfig, private router: Router, public session: SessionStorageService, public auth: AuthService, public companyService: CompanyService,
    public networkStatusService: NetworkStatusService
  ) {
    config.placement = 'bottom-right';
  }
  ngOnInit() {
    this.cookieIp = this.cookieService.get('campbell_app_ip_address');
    this.firstname = this.session.get('firstname');
    this.lastname = this.session.get('lastname');
    this.image = this.session.get('profileImage');
    this.companyName = this.session.get('companyName');

    this.networkStatusService.isOnline.subscribe(status => {
      if (status) {
        this.companyService.getSettingDetail((response) => {
          console.log("checkgetServerDetail", response);
          this.session.set('isShoreServer', response.isShoreServer);
        })
        if (this.session.get('companyIdLogo')) {
          this.companyService.getDataById(this.session.get('companyIdLogo'), (response) => {
            console.log("=======response====", response);
            if (response.responsecode === 200 || response.responsecode === '200') {
              this.companyLogo = response.data.profileImage;
            }
          })
        }
      }
    });
    this.session.set('isShoreServer', null);
  }

  Signoffsheet() {
    this.router.navigate(['/signoffsheet'])
  }

  Statussheet() {
    this.router.navigate(['/statussheet'])
  }

  logout() {
    const sessionKeys = ['profileImage', 'token', 'firstname', 'lastname', 'userSignature', 'userid', 'roleid', 'isShoreServer']
    sessionKeys.forEach(key => this.session.set(key, null))
    this.auth.logout();
  }

  openversion(data: any, id: any, index: any) {
    const dialogRef = this.dialog.open(ReleasenoteComponent, {
      width: "600px",
      height: "300px"
    });
    dialogRef.afterClosed().subscribe(() => {
    });
  }
}
