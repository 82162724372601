import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatExpansionModule} from '@angular/material/expansion';

import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HierarchyComponent } from './hierarchy/hierarchy.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule,MatDatepickerModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatTableModule, MatIconModule, MatPaginatorModule, MatSortModule, MatProgressSpinnerModule, MatCardModule, MatNativeDateModule, MatDialogModule, MatTooltipModule} from '@angular/material';
import {MatRadioModule} from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { UserComponent } from './user/user.component';
import { FieldTypeComponent } from './field-type/field-type.component';
import { WatchHourComponent } from './watch-hour/watch-hour.component';
import { DialogboxComponent } from './dialogbox/dialogbox.component';
// import { ShowHidePasswordModule } from 'ngx-show-hide-password';
// import { NgxPasswordToggleModule } from 'ngx-password-toggle';
import { SailingModesComponent } from './sailing-modes/sailing-modes.component';
import { SailingModesChangeLogComponent } from './sailing-modes-change-log/sailing-modes-change-log.component';
import { HttpClientModule } from '@angular/common/http';
import { SectionsComponent } from './sections/sections.component';
import { ReportsComponent } from './reports/reports.component';
import { FormsComponent } from './forms/forms.component';
import { FormfieldsComponent } from './formfields/formfields.component';
import { RolesComponent } from './roles/roles.component';
import { VesselsComponent } from './vessels/vessels.component';
import { VesseltypeComponent } from './vesseltype/vesseltype.component';
import { CrewComponent } from './crew/crew.component';
import { RolesandrightsComponent } from './rolesandrights/rolesandrights.component';

import { WorkflowComponent } from './workflow/workflow.component';
import { ValidationComponent } from './validation/validation.component';
import { FormworkflowComponent } from './formworkflow/formworkflow.component';
import { DateTimePickerComponent } from './controls/date-time-picker/date-time-picker.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library as fontLibrary } from '@fortawesome/fontawesome-svg-core';
import { faCalendar,  faClock } from '@fortawesome/free-regular-svg-icons';
import { DatePipe } from '@angular/common';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { FieldvalidationComponent } from './fieldvalidation/fieldvalidation.component';

import { WorkflowactionComponent } from './workflowaction/workflowaction.component';
import { LoginComponent } from './Authentication/login/login.component';
import { ClickmeComponent } from './Authentication/login/clickme.component';

import { Layout1Component } from './layout1/layout1.component';
import { Layout2Component } from './layout2/layout2.component';
import { FormbuilderComponent} from './formbuilder/formbuilder.component';
import { HierarchyTreeComponent } from './hierarchy-tree/hierarchy-tree.component';
import { TreeviewModule } from 'ngx-treeview';
import { ORBCodeComponent } from './orbcode/orbcode.component';
import { ORBCodeItemComponent } from './orbcode-item/orbcode-item.component';

import { LoadingScreenComponent } from './loading-screen/loading-screen.component';

import { ValidationDialogComponent } from './formbuilder/validation-dialog/validation-dialog.component';

import { NgxCarouselModule } from 'ngx-carousel';  
import { CospComponent } from './cosp/cosp.component';
import { EospComponent } from './eosp/eosp.component';
import { CompanyComponent } from './company/company.component';
import {MatTabsModule} from '@angular/material/tabs';
import 'hammerjs';
import { VoyagelistComponent } from './voyagelist/voyagelist.component';
import { CargoarrangementComponent } from './cargoarrangement/cargoarrangement.component';
import { ChecklistComponent } from './checklist/checklist.component';
import { ChecklistvesselsComponent } from './checklistvessels/checklistvessels.component';
import { QuestionComponent } from './question/question.component';
import { AddnoteComponent } from './addnote/addnote.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ChecklistreportsComponent } from './checklistreports/checklistreports.component';
import { QuestionsectionComponent } from './questionsection/questionsection.component';
import { CookieService } from 'ngx-cookie-service';
import { SelectAutocompleteModule } from 'mat-select-autocomplete';
import { LogBookFormsComponent } from './log-book-forms/log-book-forms.component';
import { DialoglogbookformsComponent } from './dialoglogbookforms/dialoglogbookforms.component';
import { DecklogdialogboxComponent } from './decklogdialogbox/decklogdialogbox.component';
import { EditlogbookformsComponent } from './editlogbookforms/editlogbookforms.component';
import { LandingboardComponent } from './landingboard/landingboard.component';
import { EditlogbookdialogComponent } from './editlogbookdialog/editlogbookdialog.component';
import { EditdeckdialogComponent } from './editdeckdialog/editdeckdialog.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { FormreportsComponent } from './formreports/formreports.component';
import { FormlistComponent } from './formlist/formlist.component';
import { FormlistvesselsComponent } from './formlistvessels/formlistvessels.component';
import { AddformsnotesComponent } from './addformsnotes/addformsnotes.component';
import { QuestionformsComponent } from './questionforms/questionforms.component';
import { QuestionsectionformsComponent } from './questionsectionforms/questionsectionforms.component';
import { CospalertComponent } from './cospalert/cospalert.component';
import { LightboxModule } from 'ngx-lightbox';
import { SignoffreportsComponent } from './signoffreports/signoffreports.component';
import { SignoffsheetComponent } from './signoffsheet/signoffsheet.component';
import { StatussheetreportComponent } from './statussheetreport/statussheetreport.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { StatussheetComponent } from './statussheet/statussheet.component';
import { ReleasenoteComponent } from './releasenote/releasenote.component';
import { EmailreportsComponent } from './emailreports/emailreports.component';
import { EmailchecklistreportsComponent } from './emailchecklistreports/emailchecklistreports.component';
import { EmailformreportsComponent } from './emailformreports/emailformreports.component';
import { DownloadReportComponent } from './reports/download-report/download-report.component';
import { ManageFormreportsComponent } from './formreports/manage-formreports/manage-formreports.component';
import { FormaddComponent } from './formadd/formadd.component';
import { FormpageComponent } from './formreports/formpage/formpage.component';
import { CommentDialogComponent } from './comment-dialog/comment-dialog.component';
import { WeeklyworkentryComponent } from './weeklyworkentry/weeklyworkentry.component';
import { WeeklyworkreportComponent } from './weeklyworkreport/weeklyworkreport.component';
import { WeeklypageComponent } from './weeklyworkreport/weeklypage/weeklypage.component';
import { WeeklyWorkDialogComponent } from './weekly-work-dialog/weekly-work-dialog.component';
import { CharacterCountDirective } from './character-count.directive';
import { PrettyDatePipe } from './pretty-date.pipe';

fontLibrary.add(
  faCalendar,
  faClock
);

@NgModule({
  declarations: [
    LoginComponent,
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    DashboardComponent,
    HierarchyComponent,
    UserComponent,
    FieldTypeComponent,
    WatchHourComponent,
    SailingModesComponent,
    SailingModesChangeLogComponent,
    SectionsComponent,
    ReportsComponent,
    FormsComponent,
    FormfieldsComponent,
   DialogboxComponent,

    RolesComponent,
    VesselsComponent,
    VesseltypeComponent,
    CrewComponent,
    RolesandrightsComponent,

    WorkflowComponent,

    ValidationComponent,

    FormworkflowComponent,
    DateTimePickerComponent,

    FieldvalidationComponent,

    WorkflowactionComponent,

    Layout1Component,

    Layout2Component,

    FormbuilderComponent,

    HierarchyTreeComponent,

    ORBCodeComponent,

    ORBCodeItemComponent,


    LoadingScreenComponent,

    ValidationDialogComponent,

    CospComponent,

    EospComponent,
    CompanyComponent,
    VoyagelistComponent,
    CargoarrangementComponent,
    ChecklistComponent,
    ChecklistvesselsComponent,
    QuestionComponent,
    AddnoteComponent,
    ReleasenoteComponent,
    ChecklistreportsComponent,
    QuestionsectionComponent,
    ClickmeComponent,
    FormreportsComponent,
    FormaddComponent,
    FormlistComponent,
    LogBookFormsComponent,
    DialoglogbookformsComponent,
    DecklogdialogboxComponent,
    EditlogbookformsComponent,
    LandingboardComponent,
    EditlogbookdialogComponent,
    EditdeckdialogComponent,
    FormlistvesselsComponent,
    AddformsnotesComponent,
    QuestionformsComponent,
    QuestionsectionformsComponent,
    CospalertComponent,
    SignoffreportsComponent,
    SignoffsheetComponent,
    StatussheetreportComponent,
    StatussheetComponent,
    EmailreportsComponent,
    EmailchecklistreportsComponent,
    EmailformreportsComponent,
    DownloadReportComponent,
    ManageFormreportsComponent,
    FormpageComponent,
    CommentDialogComponent,
    WeeklyworkentryComponent,
    WeeklyworkreportComponent,
    WeeklypageComponent,
    WeeklyWorkDialogComponent,
    CharacterCountDirective,
    PrettyDatePipe,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    FormsModule,
    NgbModule.forRoot(),
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    HttpClientModule,
    MatButtonModule,
    MatTabsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatIconModule,
    MatDialogModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
    // MatInputModule,
    // MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    // MatIconModule,
    // MatButtonModule,
    MatCardModule,
    // ShowHidePasswordModule,
    // NgxPasswordToggleModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FontAwesomeModule,
    SweetAlert2Module.forRoot(),
    TreeviewModule.forRoot(),
    NgxCarouselModule,
    SelectAutocompleteModule,
    MatExpansionModule,
    MatTabsModule,
    MatRadioModule,
    NgxMaterialTimepickerModule,
    MatTooltipModule,
    LightboxModule,
    Ng2SearchPipeModule


    // MatFormFieldModule
    // MatIconRegistry

  ],
  providers: [MatDatepickerModule,DatePipe,CookieService],
  bootstrap: [AppComponent],
  entryComponents:[ValidationDialogComponent,DialogboxComponent,QuestionsectionComponent,QuestionsectionformsComponent,DialoglogbookformsComponent,DecklogdialogboxComponent,EditlogbookdialogComponent,EditdeckdialogComponent,CospalertComponent,CommentDialogComponent, WeeklyWorkDialogComponent], 
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
