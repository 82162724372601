import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prettyDate'
})
export class PrettyDatePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return '';
    const [year, day, month] = value.split('-');
    return `${day}-${month}-${year}`;
  }

}
