import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';
import { HttpHeaders } from '@angular/common/http';
import { catchError, delay, retry, timeout } from 'rxjs/operators';
import * as pako from 'pako';
import { error } from 'console';
import { of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  formData = { bookTypeId: '' };

  tooltips: { [key: string]: string } = {
    sanitaryAndlaundryFacilities: `
      Verify proper lighting and drainage (Reg 3.1 - A3.1, para 6g).
      Ensure adequate ventilation (Reg 3.1 - A3.1, para 6g).
      Confirm availability of hot and cold running fresh water in wash places (Reg 3.1 - A3.1, para 11f).
      Inspect and maintain sanitary facilities on board (Reg 3.1 - A3.1, para 11f).
      Check appropriateness and functionality of laundry facilities (Reg 3.1 - A3.1, para 13).
      Ensure proper location and condition of washing machines, drying machines, irons, and ironing boards.
      Keep drying areas free of fire hazards.
      Verify the proper functioning of electrical connections (Reg 3.1 - A3.1, para 13).
    `,

    galleryAndStorageRoom: `
    Ensure lighting allows visual inspection and food preparation surfaces (Reg 3.1 - A3.1, para 6g).
Verify floor drains have sufficient capacity for washing and dishwasher spillage (Reg 3.1 - A3.1, para 6g).
Inspect clean floors without items stored directly on them; check for rodent signs (Reg 3.2 - A3.2, para 2b).
Confirm adequate ventilation for smoke, cooking smells, and chemical fumes; ensure regular cleaning (Reg 3.2 - A3.2, para 2b).
Check handwashing facilities with hot/cold water, soap, and drying options; ensure availability for use (Reg 3.1 - A3.1, 11f and Reg 3.2 - A3.2, para 2b).
Verify catering personnel have non-flammable clothes, protective gear, and cleanliness (Reg 3.2 - A3.2, para 2b).
Ensure removal of steam and chemical fumes; check for hot water and suitable equipment (Reg 3.2 - A3.2, para 2b).
Review menu plans, inspect food supplies for quality, nutritional value, and quantity; sample perishable items for expiry (Reg 3.2, para 1, Flag state guideline).
Check systems for preventing contact between cooked and raw foods; ensure separate storage, cutting boards, knives, disposable gloves, and washing facilities (Reg 3.2 - A3.2, para 2b).
Verify measures to prevent hazardous chemical exposure (Reg 3.1 - A3.1, para 6h).
  `,

    messRoom: `
    Ensure the mess room has sufficient natural and artificial light. (Reg 3.1, A3.1, para 8)
Confirm adequate ventilation in the mess rooms. (Reg 3.1, A3.1, para 7a)
Verify that mess rooms provide adequate comfort, proper furnishings, and equipment. (Reg 3.1, A3.1, para 10b)
  `,

    sleepingRoomCabin: `Verify natural and adequate artificial lighting, effective heating, and proper electrical connections in sleeping rooms/cabins. (Reg 3.1, A3.1, para 8)
Confirm adequate ventilation in sleeping rooms/cabins. (Reg 3.1, A3.1, para 7a)
Ensure cabins are free from stores. (Reg 3.1, A3.1, para 8)`,

    general: `Regularly check noise, vibration, heating, ventilation, and lighting. (For ships built after Aug 2013) (Reg 3.1, A3.1 para 4)
In ships trading to mosquito-infested ports, ensure fitting of appropriate devices as required by the competent authority. Install suitable screening on doors, ventilators, etc. (Reg 3.1, A3.1, para 16)
Confirm the presence of the "table with the shipboard working arrangements" in an easily accessible place. (Reg 2.3, A2.3, para 10)
Verify the posting of copies of MLC and DMLC in a conspicuous place accessible to seafarers. (Reg. 5.1.3, A5.1.3, para 12)
Confirm the posting of relevant occupational safety and health notices and official instructions regarding specific hazards on the ship in locations that draw the attention of seafarers. (Reg. 4.3, A4.3, para 7)`,

    hospitalAccommodation: `Check accessibility for stretcher transportation and proximity to evacuation points. (Reg 3.1, A3.1, para 12)
Check for stability of hospital bed/treatment bench, connected bathroom with washbasin, toilet, and shower. Verify regulation of lighting and ventilation. (Reg 3.1, A3.1, para 12)
Confirm the hospital is conducive to prompt and proper attention with phone line, power outlets (including emergency power), appropriate lighting for treatment, and accessibility to the patient from both sides of the treatment bench. (Reg 3.1, A3.1, para 12)
Check the medicine chest contents as prescribed by the competent authority, conducting spot checks for availability and expiry dates of some medicines. (Reg 4.1, A4.1, para 4a)
Verify the medical support system, such as radio medical or equivalent shore-based support. (Reg 4.1, A4.1, para 4d)
Ensure the availability of prescribed medical equipment as per competent authority requirements. (Reg 4.1, A4.1, para 4a) MLC`,

    chCook: `Qualification as a cook. (Reg 3.2, 3, A3.2, para 3, 4, 5, and 6)
Health and safety in food processing and consumption areas. Includes:
Temperature control
Washing/disinfection routines
Hand-washing facilities
Storing, labeling, heating, and chilling food
Separate storage for chemicals
Prevention of cross-contamination from raw to ready-to-eat food. (Reg 3.2, A3.2, para 1 and 2b)
Menu and food supply planning to meet the needs of everyone on board. (Reg 3.2, A3.2, para 2a)
Daily checks of the freezer room. (Reg 3.2, A3.2, para 2a)
Presentation of records for meal menus. (Meal menus must be prepared daily, and records must be maintained for at least one year.) (Reg 3.2, A3.2, para 2a)
Confirmation of receiving food free of charge on the ship. (Reg 3.2, para 2)`,

    bridge: `Navigational Store
Electrical wiring and Sockets
Bridge pilot chair
Bridge flooring
Bridge Toilet
Bridge access door handle
Please ensure the photos capture the current state of cleanliness, organization, and compliance with safety protocols in these areas Check the cleanliness and organization of the navigation bridge.`,

    accomodationArea: `Cabins:
Verify that cabins are clean and organized.
Ensure proper storage of personal items.
Confirm that berths are made and linens are in good condition.
Check for cleanliness of windows and curtains.
Mess Room:
Ensure tables and chairs are clean.
Verify that the mess room is free of debris and food waste.
Check that the mess room is well-ventilated.
Hospital Accommodation:
Verify that the hospital is clean and sanitized.
Check that medical equipment is stowed properly.
Confirm the availability of necessary medical supplies.`,

    onDeckStores: `Stores on Deck: FPK Stote
Check storage areas for spare ropes, Spare Wire, and other deck-related items.
Ensure proper stowage of firefighting equipment.
Verify the condition of life jackets and immwersion Suit. Verify Towing booklet inplace.`,

    engineRoom: `Stores in Engine Room:
Verify the organization of spare parts storage.
Check that chemicals and lubricants are properly labeled and stored.
Inspect stores for cleanliness and orderliness. Engine Room Bilges:
Ensure bilges are free of oil and debris.
Confirm the functionality of bilge pumping systems.
Check for signs of leaks or spills.                         Engine Room Piping Areas:
Inspect piping systems for cleanliness and integrity.
Check that pipes are properly labeled.
Verify that there are no leak.                         Workshop and Tool Storage:
Ensure the workshop area is organized and tools are properly stowed.
Verify the condition of workbenches and safety equipment.`,

    steearingRoom: `Steering Room:
Ensure the area is free from oil spills and debris.
Confirm that the floor is clean and well-maintained.
Check for any loose tools or equipment.
Steering Systems:
Verify that the steering gear is clean and properly lubricated.
Check for any signs of corrosion or wear.
Confirm that the emergency steering system is accessible and in good condition.                Storage Areas:
Inspect storage areas such are Spare mooring.
Ensure that spare parts are properly organized.
Verify that lubricants and chemicals are stored safely.`,

    OnDeckLSAFFAStore: `SOPEP Locker:
Check for cleanliness and organization inside the SOPEP locker.
Ensure that all required equipment is properly stowed and easily accessible.
Confirm the condition of spill response materials.
LSA FFA Store:
Inspect the Life-Saving Appliances (LSA) and Fire Fighting Appliances (FFA) store for cleanliness.
Verify that life jackets, immersion suits, and firefighting equipment are properly arranged.
Check expiry dates on relevant items and replace as necessary.
Fire Station:
Confirm that the fire station is well-maintained and free from obstructions.
Inspect fire hoses, nozzles, and hydrants for cleanliness and proper storage.
Ensure that fire extinguishers are in their designated locations.
CO2 Room:
Check the CO2 room for cleanliness and proper ventilation.
Verify that CO2 cylinders are securely stored and properly labeled.
Confirm that access to the CO2 room is unobstructed.
`,

    OnDeckSOPEP: `Paint Store:
Inspect the paint store for proper storage of paints and coatings.
Check for leaks or spills and address them promptly.
Ensure that paints are labeled, and invetorys are monitored. PPEs and MSDS Sheets are In Place.
Deck Crane Post:
Confirm that the deck crane post is clear of any debris or equipment.
Inspect the crane control station for cleanliness and proper functioning.
Check for any loose items that may pose a hazard during crane operations.
General Guidelines:
Verify that all housekeeping areas are well-lit and free from tripping hazards.
Ensure that safety sins and labels are clearly visible.
Conduct regular checks to maintain a high standard of cleanliness.
Train crew members on the importance of maintaining these areas.`,



  };


  constructor(public http: HttpClient, public service: ApiRootService) { }


  public getParentCategory(callback) {
    return this.http.get(this.service.getBaseUrl() + '/getAllFolderWithChildInTree').subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }

  public getLogBookDetailsById(id, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getAllFolderWithChildInTree').subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }


  public getFormByHid(callback) {
    return this.http.get(this.service.getBaseUrl() + '/getAllFormBuilder').subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }

  public getFormDetails(ids, voyageNo, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getFormDataWithAllRecordsByVoyageNo/' + ids + '/' + voyageNo).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }

  public getFormDetailsByIds(ids, voyageNo, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getFormDataWithAllRecordsByVoyageNoGroupByActionDateTime/' + ids + '/' + voyageNo).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }

  public getFormDataWithAllRecordsByVoyageNoWithFromToDateTime(ids, voyageNo, fromDate, toDate, callback) {
    return this.http.get(this.service.getBaseUrl() + '/v1/getFormDataWithAllRecordsByVoyageNoWithFromToDateTime/' + ids + '/' + voyageNo + '/' + fromDate + '/' + toDate + '/null').subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }

  public getFormDetailsByActionDateAndTime(ids, voyageNo, actionDate = '', actionTime = '', fromDate = '', toDate = '', callback) {
    //alert(actionDate+"===="+actionTime+'====='+fromDate+'========='+toDate);
    if (fromDate) {
      if (toDate == '' || toDate == null) {
        toDate = 'NoDate';
      }
      if (actionTime == '' || actionTime == null) {
        actionTime = 'NoTime';
      }
      //alert(this.service.getBaseUrl()+'/getFormDataWithAllRecordsByVoyageNoWithFromToDateTime/'+ids+'/'+voyageNo+'/'+fromDate+'/'+toDate+'/'+actionTime);
      return this.http.get(this.service.getBaseUrl() + '/getFormDataWithAllRecordsByVoyageNoWithFromToDateTime/' + ids + '/' + voyageNo + '/' + fromDate + '/' + toDate + '/' + actionTime).subscribe(
        (data) => {
          console.log(data);
          callback(data);
        });

    }
    else if (actionTime && actionTime != '') {
      //alert(this.service.getBaseUrl()+'/getFormDataWithAllRecordsByVoyageNoWithActionDateTime/'+ids+'/'+voyageNo+'/'+actionDate+'/'+actionTime);
      return this.http.get(this.service.getBaseUrl() + '/getFormDataWithAllRecordsByVoyageNoWithActionDateTime/' + ids + '/' + voyageNo + '/' + actionDate + '/' + actionTime).subscribe(
        (data) => {
          console.log(data);
          callback(data);
        });
    }



    else {
      //alert(this.service.getBaseUrl()+'/getFormDataWithAllRecordsByVoyageNoWithActionDate/'+ids+'/'+voyageNo+'/'+actionDate);
      return this.http.get(this.service.getBaseUrl() + '/getFormDataWithAllRecordsByVoyageNoWithActionDate/' + ids + '/' + voyageNo + '/' + actionDate).subscribe(
        (data) => {
          console.log(data);
          callback(data);
        });
    }

  }

  public getFormDetailsById(id, voyageNo, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getFormDataWithAllRecordsByVoyageNo/' + id + '/' + voyageNo).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }

  public getAllActiveWatchHour(callback) {
    return this.http.get(this.service.getBaseUrl() + '/getAllWatchHourWithActive').subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }


  public getWatchHourById(id, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getDLBWatchHourById/' + id).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }

  public getLatestCOSPByPasscode(id, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getLatestCOSPByPasscode/' + id).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  public updateRankSignatureByFormAndRecordId(logbookAuthorizedBy, callback) {
    return this.http.post(this.service.getBaseUrl() + '/v1/updateRankSignatureByFormAndRecordId', logbookAuthorizedBy).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  public updateRankSignatureForDeckByFormAndRecordId(logbookAuthorizedBy, callback) {
    return this.http.post(this.service.getBaseUrl() + '/updateRankSignatureForDeckByFormAndRecordId/', logbookAuthorizedBy).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  public getCrewSignByCrewId(id, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getCrewByCrewId/' + id).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  public getCOSPByvesselId(vesselId, voyageNo, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getCOSPByvesselId/' + vesselId + '/' + voyageNo).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  public getSettingDetail(callback) {
    return this.http.get(this.service.getBaseUrl() + '/getSettingDetail').subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }


  public saveComments(data, callback) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json' // Set the content type to JSON
    });

    return this.http.post(this.service.getBaseUrl() + '/saveComments', data, { headers })
      .subscribe(
        (response) => {
          console.log(response);
          callback(response);
        },
        (error) => {
          console.error('Error occurred while saving comments:', error);
        }
      );
  }

  public getCommentsDetails(rowId, formId, fieldName, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getCommentsDetailsByRowAndFormId/' + rowId + '/' + formId + '/' + fieldName).subscribe((response) => {
      console.log(response);
      callback(response);
    })
  }

  public getMasterAndChiefByVesselId(vesselId, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getAllMasterAndChiefOfficerListByVesselId/' + vesselId).subscribe((response) => {
      console.log(response);
      callback(response);
    })
  }

  // public getWeeklyFormDataByVesselIdAndFormId(formId, vesselId, callback) {
  //   return this.http.get(this.service.getBaseUrl() + '/getFormDataByVesselIdAndFormId/' + formId + '/' + vesselId).subscribe((response) => {
  //     console.log(response);
  //     callback(response);
  //   })
  // }

  public getWeeklyFormDataByVesselIdAndFormId(formId, vesselId, callback) {
    const apitimeOut = 20000;
    return this.http.get(this.service.getBaseUrl() + '/getFormDataByVesselIdAndFormId/' + formId + '/' + vesselId).subscribe((response) => {
      console.log(response);
      callback(response);
    })
  }

  public updateWeeklyDateRange(recordId, data, callback) {
    return this.http.put(this.service.getBaseUrl() + '/updateTableDataByRecordID/' + recordId, data).subscribe((response) => {
      console.log(response);
      callback(response);
    })
  }

  public getWeeklyIndiviualData(formId, vesselId, recordId, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getWeeklyWorkDoneReportByVesselIdAndFormIdAndRecordId/' + formId + '/' + vesselId + '/' + recordId).subscribe((response) => {
      console.log(response);
      callback(response);
    })
  }

  // public getWeeklyIndiviualData(formId, vesselId, recordId, callback) {
  //   return this.http
  //     .get(this.service.getBaseUrl() + '/getWeeklyWorkDoneReportByVesselIdAndFormIdAndRecordId/' + formId + '/' + vesselId + '/' + recordId)
  //     .subscribe(
  //       (response) => {
  //         // Success block
  //         console.log("API Success Response:", response);
  //         callback(response); // Pass success response to callback
  //       },
  //       (error) => {
  //         // Error block
  //         console.error("API Error Response:", error);
  //         callback({
  //           responsecode: error.status,
  //           message: 'Unexpected error occurred, Code:' +  error.status
  //         });
  //       }
  //     );
  // }


  // public getWeeklyIndiviualData(formId, vesselId, fromDate, toDate, callback) {
  //   const url = `${this.service.getBaseUrl()}/v1/getFormDataByVesselIdAndFormId/${formId}/${vesselId}/${fromDate}/${toDate}`;
  //   return this.http.get(url).subscribe((response) => {
  //     console.log(response);
  //     callback(response);
  //   }, (error) => {
  //     console.error("Error fetching data:", error);
  //   });
  // }

  // public weeklyWorkDone(data, callback) {
  //   return this.http.post(this.service.getBaseUrl() + '/createTableByFormData/', data).subscribe((response) => {
  //     console.log(response);
  //     callback(response);
  //   })
  // }

  public weeklyWorkDone(data, callback) {
    // const compressedData = pako.gzip(JSON.stringify(data));
    // const headers = new HttpHeaders({ 'Content-Encoding': 'gzip' });
    return this.http.post(this.service.getBaseUrl() + '/weeklyWorkDone/save', data).subscribe((response) => {
      console.log(response);
      callback(response);
    })
  }

  public syncShipToShore(recordId, callback) {
    return this.http.get(this.service.getBaseUrl() + '/weeklyWorkDone/syncShipToShore/' + recordId).subscribe((response) => {
      console.log(response);
      callback(response);
    })
  }

  public sendToVessel(recordId, callback) {
    return this.http.get(this.service.getBaseUrl() + '/syncCommentDetailsFromShoreToShip/' + recordId).subscribe((response) => {
      console.log(response);
      callback(response);
    })
  }

  public closeReport(recordId, callback) {
    return this.http.get(this.service.getBaseUrl() + '/weeklyWorkDoneStatusClose/' + recordId).subscribe((response) => {
      console.log(response);
      callback(response);
    })
  }
  public getWeeklyWorkDoneReortByRecordId(recordId, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getWeeklyWorkDoneReortByRecordId/' + recordId).subscribe((response) => {
      console.log(response);
      callback(response);
    })
  }








}
