import { Injectable } from '@angular/core';
import { ApiRootService } from '../apiroot.service';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class WeeklyworkreportService {

  constructor(private service: ApiRootService, private http: HttpClient) { }

  public deleteCommentByCommentId(commentId, callback) {
    return this.http.delete(this.service.getBaseUrl() + '/ddeleteCommentById' + '/' + commentId).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });
  }


}
