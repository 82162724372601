

import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AppCommonService } from 'src/app/app.common.service';
import { Location } from "@angular/common";
import { Router, NavigationEnd } from "@angular/router";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiRootService } from 'src/app/apiroot.service';
import { ReportsService } from '../reports.service';
// import { AnyTxtRecord } from 'dns';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-download-report',
  templateUrl: './download-report.component.html',
  styleUrls: ['./download-report.component.scss']
})
export class DownloadReportComponent implements OnInit {
  htmlContent: string;
  private history: string[] = [];
  isPdfLoading: boolean = false;
  pdfType: string;
  pdfTitleName: string;
  downloadPdfUrl: any;
  downloadLink: string | null = null;
  showViewReportButton: boolean = false;
  constructor(private route: ActivatedRoute, public service: ApiRootService, private _sanitizer: DomSanitizer, public commonService: AppCommonService, private location: Location, private rs: ReportsService, private router: Router, public dialogRef: MatDialogRef<DownloadReportComponent>,
    public http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    console.log("check-data", this.data);
    if (this.data && this.data.htmlContent) {
      this.htmlContent = this.transform(this.data.htmlContent);
      this.pdfTitleName = this.data.name;
      this.pdfType = this.data.pdfType;
      this.downloadPdfUrl = this.data.downloadPdfUrl;
    }
  }


  downloadPDF(pdfTitleName: any) {

    console.log(this.pdfType, this.pdfTitleName + 'pdfType');

    var isPdfLoading = document.getElementById('isPdfLoading');
    isPdfLoading.style.display = 'inline-block';
    console.log("isPdf Flag ::", this.isPdfLoading);
    var node = document.getElementById("print-section");
    const clonedNode = node.cloneNode(true) as HTMLElement;
    const addCommentButtons = clonedNode.querySelectorAll('.commentbuttonaTarget');
    addCommentButtons.forEach(button => button.remove());
    var htmlContent = clonedNode.innerHTML;
    console.log("Modified htmlContent :::", htmlContent);
    // var htmlContent = node.innerHTML;
    // console.log("htmlContent :::", htmlContent);
    if (this.pdfType === 'log') {
      this.commonService.downloadPdfCommonFn(pdfTitleName, isPdfLoading);

    } else {
      console.log("Before response ::");
      this.convertHtmlToPdf(htmlContent).subscribe((res: Blob) => {
        console.log("response ::", res);
        const blob = new Blob([res], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        this.downloadPdf(res, this.pdfTitleName + '.pdf',);
        window.URL.revokeObjectURL(url);
        isPdfLoading.style.display = 'none';
      }, (error) => {
        console.error('PDF generation failed:', error);
      });
    }
  }

  // downloadPDF(pdfTitleName: string) {
  //   console.log("PDF Type:", this.pdfType, "PDF Title Name:", pdfTitleName);

  //   const isPdfLoading = document.getElementById('isPdfLoading');
  //   isPdfLoading.style.display = 'inline-block'; // Show spinner

  //   const node = document.getElementById("print-section");
  //   const clonedNode = node.cloneNode(true) as HTMLElement;

  //   // Remove unnecessary buttons or elements
  //   const addCommentButtons = clonedNode.querySelectorAll('.commentbuttonaTarget');
  //   addCommentButtons.forEach(button => button.remove());
  //   const htmlContent = clonedNode.innerHTML;

  //   if (this.pdfType === 'log') {
  //     // Handle common PDF download scenario
  //     this.commonService.downloadPdfCommonFn(pdfTitleName, isPdfLoading);
  //   } else {
  //     console.log("Before PDF Link Response...");

  //     // Pass HTML content to the service that generates the PDF and returns a link
  //     this.convertHtmlToPdf(htmlContent).subscribe((res: any) => {
  //       console.log("Response received:", res);

  //       // Assuming res contains the link to the PDF (pdfUrl)
  //       const pdfLink = res['pdfUrl'];
  //       if (pdfLink) {
  //         console.log("PDF Link:", pdfLink);

  //         this.downloadLink = pdfLink;
  //         this.showViewReportButton = true;

  //         // Call the updated function to trigger download
  //         this.autoDownloadLink(pdfLink, pdfTitleName);
  //       } else {
  //         console.error("No PDF link found in response!");
  //       }

  //       // Hide spinner
  //       isPdfLoading.style.display = 'none';
  //     }, (error) => {
  //       console.error("PDF generation failed:", error);
  //       isPdfLoading.style.display = 'none';
  //     });
  //   }
  // }

  // autoDownloadLink(pdfLink: string, pdfTitleName: string) {
  //   console.log("Triggering download for link:", pdfLink);

  //   // Create an invisible anchor element
  //   const a = document.createElement('a');
  //   a.href = pdfLink; // Link to the PDF
  //   a.target = '_blank';
  //   a.download = pdfTitleName + '.pdf'; // Suggested filename
  //   a.style.display = 'none'; // Hide the anchor element

  //   document.body.appendChild(a); // Append to the DOM
  //   a.click(); // Programmatically trigger the download

  //   // Cleanup
  //   setTimeout(() => {
  //     document.body.removeChild(a);
  //   }, 0);
  // }




  downloadPdf(data: Blob, filename: string) {
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }

  convertHtmlToPdf(htmlContent: string, options: any = {}): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body = { html: htmlContent, ...options };

    // Convert body to JSON string to calculate size
    const bodyString = JSON.stringify(body);
    const payloadSizeInBytes = new TextEncoder().encode(bodyString).length;

    // Convert size to KB or MB for readability
    const payloadSizeInKB = (payloadSizeInBytes / 1024).toFixed(2);
    const payloadSizeInMB = (payloadSizeInBytes / (1024 * 1024)).toFixed(2);

    console.log("check-bodystring", bodyString);
    console.log(`Payload Size: ${payloadSizeInBytes} bytes (~${payloadSizeInKB} KB / ${payloadSizeInMB} MB)`);


    return this.http.post(this.downloadPdfUrl, body, { headers, responseType: 'blob' });
  }

  // convertHtmlToPdf(htmlContent: string, options: any = {}): Observable<any> {
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   const body = { html: htmlContent, ...options };
  //   const REQUEST_TIMEOUT = 300000; // 5 minutes timeout

  //   return this.http
  //     .post(this.downloadPdfUrl, body, { headers, responseType: 'blob' })
  //     .pipe(timeout(REQUEST_TIMEOUT));
  // }

  // convertHtmlToPdf(htmlContent: string, options: any = {}): Observable<any> {
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   const body = { html: htmlContent, ...options };

  //   // Convert body to JSON string to calculate size
  //   const bodyString = JSON.stringify(body);
  //   const payloadSizeInBytes = new TextEncoder().encode(bodyString).length;

  //   // Convert size to KB or MB for readability
  //   const payloadSizeInKB = (payloadSizeInBytes / 1024).toFixed(2);
  //   const payloadSizeInMB = (payloadSizeInBytes / (1024 * 1024)).toFixed(2);

  //   console.log("check-bodystring", bodyString);
  //   console.log(`Payload Size: ${payloadSizeInBytes} bytes (~${payloadSizeInKB} KB / ${payloadSizeInMB} MB)`);

  //   const REQUEST_TIMEOUT = 300000; // 5 minutes timeout

  //   return this.http
  //     .post(this.downloadPdfUrl, body, { headers, responseType: 'json' })
  //     .pipe(timeout(REQUEST_TIMEOUT));
  // }


  transform(v: any): any {
    return this._sanitizer.bypassSecurityTrustHtml(v);
  }

  back(): void {
    this.location.back();
  }

}
